import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/basestyleLogin";

const useStyles = makeStyles(styles);
export default function BaseScreenLogin(props) {
    const { /*navBars, breadCrumbsRoutes,*/ bodyColor, primaryHeader, secondaryHeader, searchHeader } = props;
    const classes = useStyles();

    /*function handleClick(event) {
        event.preventDefault();
    }*/
    return (
        <div className={classes.bodywrapper} style={{ backgroundColor: bodyColor ? bodyColor : '#FFF'}} >
            <div className={classes.wrapper} >
                {props.children}
            </div>
        </div>
    )
}
