import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import { Typography } from "@material-ui/core";
import styles from "assets/jss/chartDetailScreenstyle.js";
import { useLocation, Link, useParams } from "react-router-dom";

import D3LinearChart from "components/flujograma/D3LinearChart.js";
import FadeInModal from "components/flujograma/FadeInModal.js";
import ButtonBase from '@material-ui/core/ButtonBase';
import axios from "axios";
//import fileDownload from 'js-file-download';
import ApiURLs from "config/ApiURLs.js";
import EmbedBaseScreen from "./EmbedBaseScreen.jsx";
import { D3PieChart } from "components/indicadores/D3PieChart.jsx";
import D3Bar from "components/indicadores/D3Bar.jsx";
import D3MultipleLinearChart from "components/indicadores/D3MultipleLinearChart.jsx";

const useStyles = makeStyles(styles);



export default function EmbedScreen(props) {
    const classes = useStyles();
    let { id, type } = useParams();
    const location = useLocation();
    const [modalState, setModalState] = React.useState({ shown: false, title: undefined });
    const downloadOptions = [{ objAttribute: 'download_excel', type: 'excel', format: 'xlsx', desc: 'MSOffice Excel', faIcon: 'fas fa-file-excel fa-2x', displayableName: 'Excel' }, { objAttribute: 'download_csv', type: 'csv', format: 'csv', desc: 'CSV', faIcon: 'fas fa-file-csv fa-2x', displayableName: 'CSV' }, { objAttribute: 'download_xml', type: 'xml', format: 'xml', faIcon: 'fas fa-file-code fa-2x', desc: 'XML (de etiquetas)', displayableName: 'XML' }];
    const [chartObject, setChartObject] = React.useState({ isLoading: true, data: [] });

    const [queryString, setQueryString] = React.useState(window.location.search.length === 0 ? '' : '&' + window.location.search.substring(1, window.location.search.length));


    const closeModal = () => { //function to pass through props to FadeInModal
        setModalState({ shown: false, title: modalState.title });
    };

    React.useEffect(() => {
        axios.get(`${ApiURLs.baseURL}website/chart?id=${id}${queryString}`).then((data) => {
            setChartObject({ isLoading: false, data: data.data });
        })
    }, [location, id]);

    const hasDownloadOption = () => {
        if (chartObject.isLoading)
            return false;
        var hasValidDownloadOption = false;
        downloadOptions.forEach((element) => {
            if (chartObject.data.chart[element] != undefined)
                hasValidDownloadOption = true;
        });
        return hasValidDownloadOption;
    };

    const handleModalClick = () => {
        if (chartObject.isLoading)
            return
        setModalState({ shown: true, title: (chartObject.data.institution.name != "Eficiencia Global" ? chartObject.data.chart.title + " del " + chartObject.data.institution.name : chartObject.data.chart.title) })
    }

    const renderDownloadOptions = () => {
        if (chartObject.isLoading)
            return false;
        return downloadOptions.map((element, index) => {
            var objectAttributeCheck = chartObject.data.chart[element.objAttribute];
            if (objectAttributeCheck != undefined) {
                return (
                    <ButtonBase key={index} className={classes.buttonActionStyle} onClick={(event) => {
                        //window.open(ApiURLs.baseURL + 'website/download-chart?id=' + chartObject.data.chart.key + "&type=" + element.type, '_blank').focus();
                        let variant = "&variant=" + (chartObject.data.chart.variant == 2 ? "trimestral" : "anual");
                        window.open(ApiURLs.baseURL + 'website/generate-download?id=' + chartObject.data.institution.code + "&format=" + element.format + variant, '_blank').focus();
                    }}>
                        <div className={classes.dataOption}>
                            <div className={classes.dataOptionIconContainer}>
                                <i class={element.faIcon}></i>
                            </div>
                            <div className={classes.dataOptionTextHolder}>
                                <Typography variant="h4">{"Descargar " + element.displayableName}</Typography>
                                <div className={classes.dataOptionTextHolderExtra}>
                                    <Typography variant="subtitle2"> {"Descargar la información en formato " + element.desc}  </Typography>
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                )
            }
            else
                return null;

        })
    };

    return (
        <EmbedBaseScreen primaryHeader bodyColor="white">
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.graphOptionsMenuContainer} style={{ paddingBottom: '0.3rem', minHeight: '48px' }}>
                        <div className={classes.graphOptionsMenuContainerTitle} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <Link to={"/viewChart/" + chartObject?.data?.chart?.key + queryString} target='_parent'>
                                <Typography variant="h2" className={classes.emphasizedHeader}>{(!chartObject.isLoading) ? chartObject.data.chart?.title : '...'}</Typography>
                            </Link>
                        </div>
                        {/* <div className={classes.graphOptionsMenuButton} onClick={handleModalClick}>
                            <div className={classes.graphOptionsMenuIcon}>
                                <i class="fas fa-info fa-1x"></i>
                            </div>
                            <Typography variant="subtitle2">Metodología</Typography>
                        </div> */}
                    </div>
                    <div className={classes.graphCardContinerNew} style={{ textDecoration: 'none', paddingBottom: 0 }}>
                        <Link to={"/viewChart/" + chartObject?.data?.chart?.key + queryString} target='_parent'>
                            {
                                <div onMouseEnter={() => console.log('test')}>
                                    <div>
                                        {
                                            chartObject?.data?.chart?.type === 4 ?
                                                <D3PieChart width={600} height={355} source={chartObject.data.data} requiresLegends2 />
                                                :
                                                (
                                                    chartObject?.data?.chart?.type === 3 || chartObject?.data?.chart?.type === 6 ?
                                                        <D3Bar width={600} height={355} source={chartObject.data.data} requiresLegends2 type={chartObject?.data?.chart?.type} />
                                                        :
                                                        (
                                                            chartObject?.data?.chart?.type === 5 ?
                                                                <D3MultipleLinearChart width={600} height={355} source={chartObject.data.data} requiresLegends2 />
                                                                :
                                                                (
                                                                    chartObject?.data?.chart?.type === 1 || chartObject?.data?.chart?.type === 2 ?
                                                                        <D3LinearChart width={600} height={310} source={chartObject.data.data} requiresLegends />
                                                                        :
                                                                        null
                                                                )
                                                        )
                                                )
                                        }
                                    </div>
                                </div>
                            }
                        </Link>
                    </div>
                    {/* <div className={classes.graphContainer}>
                        <div className={classes.graphConstraint}>
                            {
                                (!chartObject.isLoading) ?
                                    <D3LinearChart width={450} height={300} source={chartObject.data.data} requiresLegends /> : ""

                            }
                        </div>
                    </div> */}
                </Grid>
            </Grid>
            <FadeInModal open={modalState.shown} handleClose={closeModal} modalTitle={modalState.title}>
                <Typography variant="caption">
                    <div dangerouslySetInnerHTML={{ __html: (!chartObject.isLoading) ? chartObject.data.chart?.methodology : '' }} />
                </Typography>
            </FadeInModal>
        </EmbedBaseScreen >
    );
}
