import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecondaryHeader from "components/flujograma/SecondaryHeader";
import SearchHeader from "components/general/SearchHeader";
import Footer from "components/flujograma/Footer";

import styles from "assets/jss/basestyle";
import PrimaryHeader from "components/indicadores/PrimaryHeader";
import IndicadoresHeader from "components/indicadores/IndicadoresHeader";

const useStyles = makeStyles(styles);

export default function BaseScreenIndicadores(props) {
    const { /*navBars, breadCrumbsRoutes,*/ bodyColor, primaryHeader, indicadoresHeader, secondaryHeader, searchHeader } = props;
    const classes = useStyles();

    /*function handleClick(event) {
        event.preventDefault();
    }*/
    return (
        <div style={{ backgroundColor: bodyColor ? bodyColor : '#FFF' }} >
            {primaryHeader ? <PrimaryHeader /> : null}
            {indicadoresHeader ? <IndicadoresHeader /> : null}
            {secondaryHeader ? <SecondaryHeader /> : null}
            {searchHeader ? <SearchHeader /> : null}
            <div className={classes.wrapper} >
                {props.children}
                <Footer />
            </div>
        </div>
    )
}
