import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/institutionScreenstyle.js";
import { Grid, Typography } from "@material-ui/core";
import BaseScreen from "./BaseScreen.js";
//import { useLocation} from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";
import LoadingIndicator from "components/general/LoadingIndicator.js";
import GraphCard from "components/flujograma/GraphCard.js";
import YoutubeEmbed from "components/general/YoutubeEmbed.js";
const useStyles = makeStyles(styles);






export default function GlobalScreen(props) {
    const classes = useStyles();
    const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] });
    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/institution?id=7').then((res) => {
            setDataRep({ isLoading: false, data: res.data });
        })
    }, []);


    const renderInstitutionData = () => {
        return (
            dataRep.data.charts.map((element, index) => {
                /*if (element.type == 1) {*/
                    return (
                        <Grid item xs={12} sm={12} md={6} key={index}>
                            <GraphCard graphData={element} justDetailButton institution={{ name: "Global" }} variant='global' />
                        </Grid>
                    )
                /*}*/
            })
        )
    };

    if (dataRep.isLoading)
        return (
            <BaseScreen primaryHeader bodyColor="#f2f3f6">
                <LoadingIndicator />
            </BaseScreen>
        )
    return (
        <BaseScreen primaryHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{ display: "Inicio", href: "/index" }, { display: "Instituciones", href: '/institutions' }, { display: dataRep.data.institutionName, href: '/flujograma/institution?id=' + dataRep.data.institutionId }]}>
            <div>
                {/* <h1 className={classes.title}>Datos de Libre acceso del Banco Mundial</h1>
                <h2 className={classes.subtitle}>Acceso abierto y gratuito a datos sobre el desarrollo en el mundo</h2> */}
                <Typography variant="h1" className={classes.title}> {
                               (!dataRep.isLoading)?dataRep.data.name:<i class="fas fa-spinner"></i> //id
                               
                    }</Typography>
                {/*<div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Datos de libre acceso</Typography>
            </div>*/}
            </div>
            <Grid container>
                <Grid container xs={12} sm={12} md={8}>
                    {renderInstitutionData()}
                </Grid>
                <Grid container xs={12} sm={12} md={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.rightSideTextContainer}>
                            {
                                (dataRep.data.video) ?
                                    <YoutubeEmbed embedId={dataRep.data.video} /> : null
                            }
                            {
                                (dataRep.data.description) ?
                                    <div style={{marginTop:dataRep.data.video?'10px':undefined}}>
                                        <Typography variant="subtitle2">{dataRep.data.description}</Typography>
                                    </div> : null
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </BaseScreen>
    )
}