const primaryScreenstyle = (theme) => ({
    title: {
        textAlign: 'left',
        fontSize: '2.3rem',// now its beging inherited from customTheme on index.js
        marginBottom:'0',
        paddingLeft: "10px",
        // [theme.breakpoints.up(768)]: { //now its beging inherited from customTheme on index.js
        //     fontSize: '3rem !important'
        // },
    },
    typeParent:{
        display: 'flex',
        justifyContent: 'center'
    },
    typeContainer:{
        textAlign: 'center',
        fontSize: '2.3rem',// now its beging inherited from customTheme on index.js
        marginBottom:'0',
        paddingLeft: "10px",
        width: 'fit-content'
    },
    subtitleContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'left',
        paddingLeft: "10px"
    },
    subtitle: {
        marginTop:'5px',
        textAlign: 'left',
        color: '#81878C',
        fontWeight:'normal',
        maxWidth:'450px'
    },
    longDescription:{
        width:"100%"
    },
    rightSideTextContainer:{
        marginTop:'10px',
        height:"218px" //to make it match with cards default margin 
    },
    activeExtraGrid:{
        display: "flex",
        height: "auto",
        opacity: 1.0,
        transition:"ease all 0.7s"
    },
    hiddenExtraGrid:{
        height: "0px",
        opacity: 0.00,
        transition:"ease all 0.7s"
    },
    divExtraButtonCont:{
        textAlign:'center',
        padding: '1rem',
        width: '100%'
    },
    footerContainer:{
        marginTop: '1rem',
        display:'flex',
        flexDirection:'row',
        justifyContent:'left',
        paddingLeft: "10px",
        width: 'calc(100% - 10px)'
    }
})

export default primaryScreenstyle;