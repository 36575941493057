import React from "react";
import BaseScreenLogin from "./BaseScreenLogin";
import { Button, Grid, Input, Link, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/basestyleLogin";
import logo from 'assets/img/Logo-justiciacriminal.svg'
import { useState } from "react";
import Axios from 'services/BackendApi/BackendApi';
const useStyles = makeStyles(styles);

export default function LoginScreen(props){
    
    const [formerrors,setFormErrors] = useState({});
    const [formData,setFormData] = useState({email:"",password:'', rememberMe:false});
    const [operationStatus,setOperationStatus] = useState({status: 0,message:''});
    const classes = useStyles();
    const onchangeInput = (e) =>{
        let newErrors = {formerrors};
        let newFormData = {...formData};
        newFormData[e.target.name] = e.target.value;
        if (newErrors.hasOwnProperty(e.target.name)) delete newErrors[e.target.name];
        setFormErrors(newErrors);
        setFormData(newFormData);
      }
    const validateData = () =>{
        //alert('eureka');
        console.log(formData);
        let newErrors = {};
        setFormErrors(newErrors);
        if (formData.email.trim()==="") newErrors["email"] = "El email no puede estar vacío";
        else
          if (!String(formData.email).toLowerCase().match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ))  newErrors["email"] = "E-mail no válido";

        if (formData.password.trim()==="") newErrors["password"] = "Por favor ingrese su password";
        setFormErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
          return true;
        } else {
          return false;
        }
    }
    const submitHandler = (e) => {
        let result = null;
            e.preventDefault();
        if (validateData()){
            loginUser();
            //dispatch(signin(formData.email, formData.password, false));
           
        }
    };

    async function loginUser () {

        const search = window.location.search;
	    const params = new URLSearchParams(search);
	    const returnTo = params.get('returnTo');
        //try {
            //console.log(username,password,remember);
            const { data } = await Axios.UserLogin({
                username:formData.email,
                password: formData.password,
                remember: formData.rememberMe
            });
            console.log("Response login:",data);
            if (data.success) {
                //dispatch(signInUser(data));
                localStorage.setItem('userInfo', JSON.stringify(data));
                Axios.resetService();
                if (returnTo){
                    window.location.href=returnTo;
                }else{
                    window.location.href="/"; // The router should handle this default route
                }
            } else {
                //dispatch(signInFailed(data.message));
                return data;
            }
        //} catch (error) {
            /*dispatch(signInFailed(
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
            ));*/
        
          //  return error.response && error.response.data.message
          //  ? error.response.data.message
          //  : error.message;
        //}
    }
    function renderStatus(){
        switch (operationStatus){
            case 0: return ""; break;
            case 1: return "Autenticando usuario..."; break;
            case 2: return "Ingresando a plataforma"; break;
            case -1: return "Error de autenticación, "+operationStatus.message; break;
        }
    }
    return <BaseScreenLogin>
        <form  onSubmit={submitHandler}>
            <Grid container spacing={2} className={classes.formcontainer}>
                <Grid item xs={12}>
                    {renderStatus()}
                </Grid>
                <Grid item xs={12}>
                    <img src={logo} className={classes.loginlogo}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.logininput} variant="outlined" label="E-mail" name="email" onChange={onchangeInput}  /> 
                    {
                        (formerrors.email)
                    }
                </Grid>           
                <Grid item xs={12}>
                    <TextField className={classes.logininput}  variant="outlined" label="Password" name="password" type="password" onChange={onchangeInput}  />
                    {
                        (formerrors.password)
                    }
                </Grid>
                <Grid item xs={12} className={classes.buttonsline}>
                    <Button variant="contained" className={classes.loginbutton} onClick={submitHandler} type="submit">Ingresar</Button>
                </Grid>
                <Grid item xs={12} className={classes.buttonsline}>
                    <Link href="/user/forgot">Olvidé mi contraseña</Link>
                </Grid>
            </Grid>
        </form>
    </BaseScreenLogin>;
}