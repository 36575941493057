const basestyle = (theme)=>({
    wrapper:{
        margin:'0 auto',
        maxWidth:'90%',
        maxWidth:"30vw",
        padding:'15px 0 0',
        textAlign:'center',
        [theme.breakpoints.down('md')]: {
            maxWidth: '90vw !important'
        },
    },
    formcontainer:{
        [theme.breakpoints.down('md')]: {
            maxWidth: '90vw !important'
        },
        
    },
    contwrapper:{
        margin:"auto"
    },
    bodywrapper:{
        paddingTop:'20vh',
        width: "100vw",
        height: "100vh"
    },
    logininput:{
        width:"30vw",
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    loginlogo:{
        maxWidth:"20vw",
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '50vw'
        },
    },
    loginbutton:{
        fontSize: '1.1rem',
        backgroundColor: "rgb(68, 114, 196)",
        color:'white',
        fontWeight: 'bold',
        '&:hover':{
            backgroundColor: "rgb(91, 155, 213)"
        }
    },
    buttonsline:{
        textAlign:"right",
        [theme.breakpoints.down('md')]: {
            maxWidth: '90vw !important'
        },
    }
})
export default basestyle;