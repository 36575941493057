import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import BaseCard from "components/flujograma/BaseCard.js";
import styles from "assets/jss/baseCardstyle.js";
import styles2 from "assets/jss/institutionScreenstyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function DashboardDescription(props) {
    const { description, bottom } = props;
    const classes = useStyles();
    const classes2 = useStyles2();

    return (
        <BaseCard>
            <div className={classes.container}>
                <Typography variant="caption">
                    <p>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </p>
                </Typography>
            </div>
            {
                bottom !== null ?
                    <div className={classes2.footerContainer} style={{ paddingBottom: '0.4rem' }}>
                        <Typography variant className={classes2.longDescription}>
                            <div dangerouslySetInnerHTML={{ __html: bottom }} />
                        </Typography>
                    </div>
                    : null
            }
        </BaseCard>
    );
}