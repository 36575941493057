import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/embedbasestyle";
const useStyles = makeStyles(styles);

export default function EmbedBaseScreen(props) {
    const { bodyColor } = props;
    const classes = useStyles();
    React.useEffect(() => {
        function handleResize() {
            let xMust, yMust, xNow, yNow, xAfter, yAfter, xRatio, yRatio;
            xMust = window.innerWidth;
            yMust = window.innerHeight;
            let embedCont = document.getElementById('embedcont');
            xNow = embedCont.offsetWidth;
            yNow = embedCont.offsetHeight;
            xRatio = xNow / yNow;
            yRatio = yNow / xNow;

            embedCont.style.width = xMust.toString() + "px";
            embedCont.style.height = "auto";

            xAfter = embedCont.offsetWidth;
            yAfter = embedCont.offsetHeight;

            if (yAfter > yMust) {
                //salimos del viewport
                embedCont.style.height = yMust.toString() + "px";
                embedCont.style.width = (xMust * yRatio).toString() + "px";
            }
            console.log('Resize executed', window.location.href);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
    });
    return (
        <div style={{ backgroundColor: bodyColor ? bodyColor : '#FFF' }}>
            <div className={classes.wrapper} id="embedcont">
                {props.children}
            </div>
        </div>
    )
}
