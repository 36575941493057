const generalColors = [
    "#4472C4",
    "#A5A5A5",
    "#5B9BD5",
    "#264478",
    "#636363",
    "#255E91",
    "#698ED0",
    "#B7B7B7",
    "#7CAFDD",
    "#335AA1",
    "#848484",
    "#327DC2",
    "#8FAADC",
    "#C9C9C9",
    "#9DC3E6",
    ///11/08/2023 
];

const chartColors = {
    barChart: generalColors,
    pieChart: generalColors,
    lineChart: [
        "#264478",
        '#A5A5A5',
        '#4472C4',
        '#5B9BD5',
        '#636363',
        ///10/08/2023,
        "#255E91",
        "#698ED0",
        "#B7B7B7",
        "#7CAFDD",
        "#335AA1",
        "#848484",
        "#327DC2",
        "#8FAADC",
        "#C9C9C9",
        "#9DC3E6", 
    ],
    total: 'black'
};

export default chartColors;
