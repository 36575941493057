import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseScreen from "./BaseScreen.jsx";
import { Typography } from "@material-ui/core";
import styles from "assets/jss/primaryScreenstyle.js";
import { useLocation} from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";
const useStyles = makeStyles(styles);

export default function TermsAndConditionsScreenGeneral(props) {
    //const rootRef = React.useRef(null);
    const location = useLocation();
    const classes = useStyles();
    const [pageObject, setPageObject] = React.useState({isLoading:true, data:{title:'...'}});
   
    useEffect(()=>{
        axios.get(ApiURLs.baseURL + 'website/page?id=' + location.pathname ).then((data) => {  
            setPageObject({isLoading:false,data:data.data});
        })
    }
    ,[location]);

    return (
        <BaseScreen primaryHeader bodyColor="#f2f3f6">
            {(pageObject.isLoading)? 
                <div>
                    ...
                </div>:
                <div>
                    <Typography variant="h1" className={classes.title}>{pageObject.data.title}</Typography>
                    <div className={classes.subtitleContainer}>
                        <Typography variant="body1" >
                            
                            <div dangerouslySetInnerHTML={ {__html: pageObject.data.content} } />
                        </Typography>
                    </div>
                </div>
            }
        </BaseScreen>
    )
}
