const primaryScreenstyle = (theme) => ({
    title: {
        textAlign: 'leftr',
        //fontSize: '2.4rem', now its beging inherited from customTheme on index.js
        marginBottom:'0',
        // [theme.breakpoints.up(768)]: { //now its beging inherited from customTheme on index.js
        //     fontSize: '3rem !important'
        // },
    },
    subtitleContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'left',
        paddingBottom:"10px"
    },
    subtitle: {
        marginTop:'5px',
        textAlign: 'left',
        color: '#81878C',
        fontWeight:'normal',
        maxWidth:'450px'
    },
    listCard:{
        background:"white",
        width:"100%",
    },
    institutionList:{
        fontSize:"2rem",
    },
    institutionItem:{
        fontSize:"2rem !important",
    }
})

export default primaryScreenstyle;