const basestyle = (theme)=>({
    wrapper:{
        margin:'0 auto',
        maxWidth:'90%',
        width:'1160px',
        padding:'15px 0 0',
    },
    contwrapper:{
        margin:"auto"
    },
    bodywrapper:{
        paddingTop:'20vh',
        width: "100vw",
        height: "100vh"
    },
    logininput:{
        width:"30vw",
        [theme.breakpoints.down('md')]: {
            width: '60vw'
        },
    },
    loginlogo:{
        maxWidth:"20vw",
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '50vw'
        },
    },
    loginbutton:{
        fontSize: '1.1rem',
        backgroundColor: "rgb(68, 114, 196)",
        color:'white',
        fontWeight: 'bold',
        '&:hover':{
            backgroundColor: "rgb(91, 155, 213)"
        }
    }
})
export default basestyle;