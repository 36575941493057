const screenStyle = (theme) => ({
    graphOptionsMenuContainer:{
        backgroundColor:'white',
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        border: '1px solid '+theme.palette.secondary.lighter,
        borderStyle:'none',
        borderBottomStyle:'solid'
    },
    graphOptionsMenuContainerTitle:{
        flex:1,
        paddingTop:"5px",
        paddingLeft:"10px",
        display:"flex"
    },
    graphOptionsMenuButton:{
        display:'flex',
        alignItems:'center',
        backgroundColor:"#f2f3f6",
        height: '20px',
        margin:'6px',
        padding:'4px 10px',
        cursor:'pointer',
        boxShadow:'0 2px 0 '+theme.palette.secondary.lighter,
        '&:hover':{
            backgroundColor:theme.palette.hover.backgroundColor   
        }
    },
    graphOptionsMenuIcon:{
        height:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        marginRight:'7px'
    },
    flujogramaImageContainer:{
        width:'100%',
        maxHeight:'460px',
    },
    videoContainer:{
        backgroundColor:'white',
        border:'1px solid '+theme.palette.secondary.lighter,
        borderRadius:'4px'
    },
    rightContentContainer:{
        marginTop:'10px',
        [theme.breakpoints.up('md')]: { 
            paddingLeft:'20px',
            marginTop:'0 !important'
        },
    },
    rightSideTextContainer:{
        marginTop:'10px',
        height:"218px"
    },
    root: {
		display: 'flex',
		borderRadius: "0px",
		alignItems:"flex-start",
	},
    details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
		padding:"0px",
		padding:"0px 10px"
	},
	cover: {
		width: "150px !important",
		height: "100px",
		minWidth: "150px"
	},
    divisoryClass:{
        [theme.breakpoints.up(768)]: { 
            marginBottom:'10px'
        },
    },
    FeedItem: {

        textAlign: "left !important",
        alignItems: 'flex-start'
    },
    svgImage:{
        width: "100%",
        height: "auto"
    }
});
export default screenStyle;