const primaryScreenstyle = (theme) => ({
    title: {
        textAlign: 'left',
        //fontSize: '2.4rem', now its beging inherited from customTheme on index.js
        marginBottom:'0',
        fontSize:'2rem',
        marginBottom:'15px',
        [theme.breakpoints.up(768)]: { 
            fontSize:'2.3rem !important'
        },
    },
    subtitle:{
        color:theme.palette.secondary.light
    },
    graphContainer:{
        backgroundColor:'white',
        width:'100%'
    },
    graphConstraint:{
        [theme.breakpoints.down('lg')]: { 
            padding: '0px 30px',
        },
    },
    graphOptionsMenuContainer:{
        backgroundColor:'white',
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        border: '1px solid '+theme.palette.secondary.lighter,
        borderStyle:'none',
        borderBottomStyle:'solid'
    },
    graphOptionsMenuContainerTitle:{
        flex:1,
        paddingTop:"5px",
        paddingLeft:"10px",
        display:"flex"
    },
    graphOptionsMenuButton:{
        display:'flex',
        alignItems:'center',
        backgroundColor:"#f2f3f6",
        height: '20px',
        margin:'6px',
        padding:'4px 10px',
        cursor:'pointer',
        boxShadow:'0 2px 0 '+theme.palette.secondary.lighter,
        '&:hover':{
            backgroundColor:theme.palette.hover.backgroundColor   
        }
    },
    graphOptionsMenuIcon:{
        height:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        marginRight:'7px'
    },
    dataOptions:{
        backgroundColor:'white',
        border:'1px solid '+theme.palette.secondary.lighter,
        borderRadius:'4px'
    },
    dataOption:{
        display:'flex',
        flexDirection:'row',
        padding:'10px 20px',
        border: '1px solid '+theme.palette.secondary.lighter,
        padding:'10px',
        borderBottom:'none !important',
        borderLeft:'none !important',
        borderRight:'none !important',
        width:"100%",
    },
    rightContentContainer:{
        marginTop:'10px',
        [theme.breakpoints.up('md')]: { 
            paddingLeft:'20px',
            marginTop:'0 !important'
        },
    },
    dataOptionIconContainer:{
        color:theme.palette.secondary.light,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        width:'60px',
        height:'60px',
        border: '1px solid '+theme.palette.secondary.lighter,
        borderRadius:'50%'
    },
    dataOptionTextHolder:{
        marginLeft:'10px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        flex:'1'
    },
    dataOptionTextHolderExtra:{
        color:theme.palette.secondary.light
    },
    divisoryClass:{
        [theme.breakpoints.up(768)]: { 
            marginBottom:'10px'
        },
    },
    modalBody:{
        backgroundColor:'white',
        position:'relative',
        padding:'15px'
    },
    buttonActionStyle:{
        textAlign:"left",
        width: "100%"
    }
})

export default primaryScreenstyle;