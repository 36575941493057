import axios from "axios";
import React from "react";
import ApiURLs from '../config/ApiURLs.js';

export const AppContext = React.createContext({ isLoading: true, institutions: [], indicadoresInstitutions: [] });

export const AppProvider = (props) => {
    const [state, setState] = React.useState({ isLoading: true, institutions: [], indicadoresInstitutions: [] });
    const [userInfo, setUserInfo] = React.useState({});
    //const [websiteAssets, setWebsiteAssets] = React.useState({isLoading:true, data:[]});
    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + "website/institutions?location=FLUJOGRAMA").then((data) => {
            //setState({isLoading:false,institutions:data.data.data});
            setState((prev) => ({ ...prev, isLoading: false, institutions: data.data }));
        });
        /*axios.get(ApiURLs.baseURL+"website/assets").then((data)=>{
            setWebsiteAssets({isLoading:false,data:data.data});
        });*/

        axios.get(`${ApiURLs.baseURL}website/institutions?location=INDICADORES`).then((data) => {
            //setState({isLoading:false,institutions:data.data.data});
            setState((prev) => ({ ...prev, isLoading: false, indicadoresInstitutions: data.data }));
        });
    }, []);

    React.useEffect(() => {
        const search = window.location.search;
        let token = { access_token: '' };
        const params = new URLSearchParams(search);
        const tokenParam = params.get('token');

        if (tokenParam) {
            token.access_token = tokenParam;
        }

        let userLocal = {};
        if (token.access_token === '') {
            userLocal = sessionStorage.getItem('userInfo')
                ? JSON.parse(sessionStorage.getItem('userInfo'))
                : null;
            if (null !== userLocal) {
                if (userLocal.access_token) {
                    token = userLocal;
                } else {
                    document.cookie = ApiURLs.cookie + "=" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    sessionStorage.removeItem('userInfo');
                    userLocal = null;
                    return;
                }
            } else {
                var match = document.cookie.match(new RegExp('(^| )' + ApiURLs.cookie + '=([^;]+)'));
                if (match) {
                    token.access_token = match[2];
                } else {
                    userLocal = null;
                    return;
                }
            }
        }
        const body = {};
        axios.post(`${ApiURLs.baseURL}api/session`, body, {
            headers: { Authorization: `Bearer ${token.access_token}`, },
        }).then(function (response) {
            if (!response.data.error) {
                sessionStorage.removeItem('userInfo');
                const session_data = JSON.stringify(response.data);
                sessionStorage.setItem("userInfo", session_data);

                var expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + (0.5 * 60 * 60 * 1000)); // Convert hours to milliseconds

                var expires = "expires=" + expirationDate.toUTCString();
                document.cookie = ApiURLs.cookie + "=" + token.access_token + ';' + expires + ';Path=/';

                setUserInfo(response.data);
            } else {
                sessionStorage.removeItem('userInfo');
                document.cookie = ApiURLs.cookie + "=" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location.replace(`${window.location.host}/bienvenido`);
                setUserInfo({});
            }
        }).catch(function (err) {
            sessionStorage.removeItem('userInfo');
            document.cookie = ApiURLs.cookie + "=" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            setUserInfo({});
            window.location.replace(`${window.location.host}/bienvenido`);
        }).finally(function () { });
    }, []);

    return (
        <AppContext.Provider value={{ "state": state, "userInfo": userInfo /*,"websiteData":websiteAssets*/ }} state={state} userInfo={userInfo}>
            {props.children}
        </AppContext.Provider>
    )
};

export default AppContext;
