import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "assets/jss/primaryScreenstyle.js";
import BaseScreenIndicadores from "./BaseScreen";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles(styles);

export default function TitleAndBodyScreenIndicadores(props) {
    const location = useLocation();
    const classes = useStyles();
    const [pageObject, setPageObject] = React.useState({ isLoading: true, data: { title: '...' } });

    useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/page?id=' + location.pathname).then((data) => {
            setPageObject({ isLoading: false, data: data.data });
        })
    }
        , [location]);

    return (
        <BaseScreenIndicadores indicadoresHeader bodyColor="#f2f3f6">
            {(pageObject.isLoading) ?
                <div>
                    ...
                </div> :
                <div>
                    <Typography variant="h1" className={classes.title}>{pageObject.data.title}</Typography>
                    <div className={classes.subtitleContainer}>
                        <Typography variant="body1" >

                            <div dangerouslySetInnerHTML={{ __html: pageObject.data.content }} />
                        </Typography>
                    </div>
                </div>
            }
        </BaseScreenIndicadores>
    )
}
