
// La conexión la diferencia dependiendo el ambiente en el que esté
//const ApiHostName = window.location.hostname+'/api';
var ApiHostName = "";

switch (window.location.hostname){
	case "dev.justiciacriminal.gt":{
		ApiHostName = "app.justiciacriminal.gt";
	}break;
	case "justiciacriminal.local":{
		ApiHostName ="flujograma.app.local";
	}break;
	case "justiciacriminal.gt":{
		ApiHostName = "app.justiciacriminal.gt";
	}break;
	default:{
		ApiHostName = "app.justiciacriminal.gt";
	}break;
}



const ApiURLs = {
	publicFilesURL: 'https://'+ApiHostName+'/v1/',
	baseURL: window.location.protocol + '//' + ApiHostName + '/v1/',
	ProveedorSignUp: {
		href: '/user/signup',
		method: 'post'
	},
	ProveedorForgot: {
		href: '/user/forgot',
		method: 'post'
	},
	UserLogin: {
		href: '/auth/authenticate',
		method: 'post',
		requires: ['username', 'password', 'remember'],
	}
};
export default ApiURLs;
