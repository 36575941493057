import React, { useRef } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as d3 from "d3";

import styles from "./pie-chart.module.css";
import styles2 from "assets/jss/linearChartstyle.js";
import { useState } from "react";
import chartColors from "assets/jss/chartsColors";
import { useEffect } from "react";
const useStyles = makeStyles(styles2);

var color = "#1f95dd";

// const colors = ["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56"];

export const data = [
    { name: "Mark", value: 90 },
    { name: "Robert", value: 12 },
    { name: "Emily", value: 34 },
    { name: "Marion", value: 53 },
    { name: "Nicolas", value: 58 },
]

const MARGIN_X = 150;
const MARGIN_Y = 50;
const INFLEXION_PADDING = 20; // space between donut and label inflexion point

export const D3PieChart = (props) => {
    const classes = useStyles();

    const ref = useRef(null);
    const divRef = useRef(null);

    const { source, isPreview, width, height, requiresLegends, requiresLegends2, portadaScreen } = props;

    const [heightDiv, setHeight] = useState(0)
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight,]);

    const [dataRep] = useState({ isLoading: true, data: source, scaledBy: undefined });

    const radius = (requiresLegends2 ? -4 : 0) + Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;

    const dataValues = [];

    useEffect(() => {
        if (windowSize[1] >= 900 || windowSize[1] <= 1200) {
            if (divRef.current.clientHeight > 61) {
                setHeight(70);
            } else {
                setHeight(60);
            }
        }
    }, [windowSize]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setHeight(divRef?.current?.clientHeight);
    }, [divRef?.current?.clientHeight]);

    dataRep.data.forEach(obj => {
        dataValues.push({ key: obj.name, value: obj.val, per: obj.per, currency: obj.currency });
    });

    const dataKeys = dataValues.map(val => val.key);

    const pie = () => {
        const pieGenerator = d3.pie().value((d) => d.per);
        return pieGenerator(dataValues);
    };

    const arcGenerator = d3.arc();

    const numberWithCommas = (x) => {
        return parseFloat(x).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const capitalizeString = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1, name.length);
    }

    const determineCircleColor = (elementGroup) => {
        if (elementGroup === undefined)
            return color;//fallback
        var index = dataKeys.indexOf(elementGroup);
        return chartColors.pieChart[index];
    }

    const renderLegends = () => {
        return (
            <div ref={divRef} className={classes.graphLegendsContainer} style={{ marginTop: heightDiv > 61 ? '-48px' : (portadaScreen ? '-28px' : '-2px') }}>
                {
                    dataKeys.map((element, index) => {
                        return (
                            <div key={index} className={classes.graphLegend}>
                                <div className={classes.graphLegendVisualRepresentationNew} style={{ background: determineCircleColor(element), height: '10px', width: '10px' }} />
                                <div>
                                    <Typography className={requiresLegends2 ? classes.graphLegendTextNew : ''} variant="subtitle2">{capitalizeString(element)}</Typography>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    function Shape(props) {
        const { grp, i } = props;

        const [focused, setFocused] = useState(false);
        // First arc is for the Pie
        const sliceInfo = {
            innerRadius: 0,
            outerRadius: radius,
            startAngle: grp.startAngle,
            endAngle: grp.endAngle,
        };

        /*  const [focused, setFocused] = useState(false); */
        const centroid = arcGenerator.centroid(sliceInfo);
        const slicePath = arcGenerator(sliceInfo);
        // Second arc is for the legend inflexion point
        const inflexionInfo = {
            innerRadius: radius + INFLEXION_PADDING/*  + (i % 3 === 0 ? -30 : 0) */,
            outerRadius: radius + INFLEXION_PADDING/*  + (i % 3 === 1 ? 40 : 0) */,
            startAngle: grp.startAngle,
            endAngle: grp.endAngle,
        };
        const inflexionPoint = arcGenerator.centroid(inflexionInfo);

        const isRightLabel = inflexionPoint[0] > 0;
        const labelPosX = inflexionPoint[0] + 20 * (isRightLabel ? 1 : -1);
        const textAnchor = isRightLabel ? "start" : "end";
        const per = (grp.data.per * 100).toFixed(2) + "%";
        const label = grp.data.key;
        let valueLabel = '';
        if (grp.data.value === '') {
            valueLabel = (grp.data.currency !== undefined ? grp.data.currency : '') + per;
        } else {
            valueLabel = (grp.data.currency !== undefined ? grp.data.currency : '') + numberWithCommas(grp.data.value) + " (" + per + ")";
        }

        return (
            <g
                key={i}
                className={styles.slice}
                onMouseEnter={() => {
                    if (ref.current) {
                        setFocused(true);
                        ref.current.classList.add(styles.hasHighlight);
                    }
                }}
                onMouseLeave={() => {
                    if (ref.current) {
                        setFocused(false);
                        ref.current.classList.remove(styles.hasHighlight);
                    }
                }}
            >
                <path d={slicePath} fill={chartColors.pieChart[i]} />
                {
                    focused ?
                        <>
                            <circle cx={centroid[0]} cy={centroid[1]} r={2} /><line
                                x1={centroid[0]}
                                y1={centroid[1]}
                                x2={inflexionPoint[0]}
                                y2={inflexionPoint[1]}
                                stroke={"black"}
                                fill={"black"}
                            />
                            <line
                                x1={inflexionPoint[0]}
                                y1={inflexionPoint[1]}
                                x2={labelPosX}
                                y2={inflexionPoint[1]}
                                stroke={"black"}
                                fill={"black"}
                            />
                            <text
                                x={labelPosX + (isRightLabel ? 2 : -2)}
                                y={inflexionPoint[1]}
                                textAnchor={textAnchor}
                                dominantBaseline="middle"
                                style={{ fontSize: 10, maxWidth: '8rem', overflow: 'auto', 'user-select': 'none' }}
                                opacity={0}
                            >
                                {valueLabel}
                            </text>
                            <text
                                x={labelPosX + (isRightLabel ? 2 : -2)}
                                y={inflexionPoint[1]}
                                textAnchor={textAnchor}
                                dominantBaseline="middle"
                                style={{ fontSize: 10, maxWidth: '8rem', overflow: 'auto' }}
                            >
                                {label}
                            </text>
                            <text
                                x={labelPosX + (isRightLabel ? 2 : -2)}
                                y={inflexionPoint[1] + 12}
                                textAnchor={textAnchor}
                                dominantBaseline="middle"
                                style={{ fontSize: 10, maxWidth: '8rem', overflow: 'auto' }}
                            >
                                {valueLabel}
                            </text>
                        </>
                        :
                        (grp.data.per * 100).toFixed(2) < 4 && !focused ? <></> :
                            <>
                                <circle cx={centroid[0]} cy={centroid[1]} r={2} /><line
                                    x1={centroid[0]}
                                    y1={centroid[1]}
                                    x2={inflexionPoint[0]}
                                    y2={inflexionPoint[1]}
                                    stroke={"black"}
                                    fill={"black"}
                                />
                                <line
                                    x1={inflexionPoint[0]}
                                    y1={inflexionPoint[1]}
                                    x2={labelPosX}
                                    y2={inflexionPoint[1]}
                                    stroke={"black"}
                                    fill={"black"}
                                />
                                <text
                                    x={labelPosX + (isRightLabel ? 2 : -2)}
                                    y={inflexionPoint[1]}
                                    textAnchor={textAnchor}
                                    dominantBaseline="middle"
                                    style={{ fontSize: 10, maxWidth: '8rem', overflow: 'auto' }}
                                >
                                    {valueLabel}
                                </text>
                            </>
                }
            </g>
        );
    }

    const shapes = pie().map((grp, i) => <Shape grp={grp} i={i} />);

    return (
        <div style={{ backgroundColor: 'white', padding: requiresLegends ? (/* dataRep.scaledBy ? '25px 0px' : */ '15px 0px 25px 0px') : requiresLegends2 ? '0px 0px 0px' : '10px 0px 0px' }}>
            <svg style={{ background: '#ffffff' }} viewBox={`0 0 ${width} ${isPreview ? height : height + 20}`} >
                <g
                    transform={`translate(${width / 2}, ${(height / 2)})`}
                    className={styles.container}
                    ref={ref}
                >
                    {shapes}
                </g>
            </svg>
            {
                isPreview ? null : (requiresLegends ? renderLegends() : null)
            }
            {
                requiresLegends2 ? renderLegends() : null
            }
        </div>
    );
};