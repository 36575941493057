import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseCard from "components/flujograma/BaseCard";
import styles from "assets/jss/baseCardstyle.js";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom"
const useStyles = makeStyles(styles);

export default function SlideshowCard(props) {
    const { slideElements } = props;
    const classes = useStyles();
    return (
        <BaseCard height={props.height}>
            <Link to={((slideElements.displayonflujogram == 1) ? "/flujograma" : '') + "/viewChart/" + slideElements[0].chartId} style={{ textDecoration: 'none' }}>
                <div className={classes.slideshowContainer + ' ' + classes.slideshowContainerNewCharts} >
                    {
                        <div>
                            <div>
                                {
                                    slideElements[0].main
                                }
                            </div>
                            <div >
                                <Typography variant="h3" className={classes.mediaTitle} style={{ fontSize: slideElements[0].title.length > 20 ? '1.6rem' : '1.8rem' }}>{slideElements[0].title}</Typography>
                                <Typography variant="caption" className={classes.mediaSubtitle}>{slideElements[0].subtitle}</Typography>
                            </div>
                        </div>
                    }
                </div>
            </Link>
        </BaseCard>
    );
}