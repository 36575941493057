const baseCardstyle = (theme) => ({
    container: {
        margin: '10px',
        padding: '0px 10px',
        border: '1px solid black',
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            margin: '10px 0px !important' //sin margen horizontal pero mantenemos el vertical
        },
    },
    //460px
    containerFooterCase: {
        margin: '10px 10px 0px',
        border: '1px solid ' + theme.palette.secondary.lighter,
        borderRadius: '2px 2px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: '10px 0px 0px !important' //sin margen horizontal pero mantenemos el vertical
        },
    },
    containerFooterCaseNew: {
        height: '98%',
        margin: '10px 10px 0px',
        border: '1px solid ' + theme.palette.secondary.lighter,
        borderRadius: '2px 2px 0 0',
        [theme.breakpoints.down('xs')]: {
            margin: '10px 0px 0px !important' //sin margen horizontal pero mantenemos el vertical
        },
    },
    headerContainer: {
        padding: '0 10px',
        borderBottom: '1px solid' + theme.palette.secondary.lighter,
        position: 'relative',
        height: '40px'
    },
    header: {
        padding: '0 ',//to override heading tags pre-configured margin and paddings
        margin: '0',//to override heading tags pre-configured margin and paddings
        fontSize: '1.2rem',
        fontWeight: 'normal',
        lineHeight: '40px',
        color: theme.palette.secondary.light
    },
    emphasizedHeader: {
        padding: '0 ',//to override heading tags pre-configured margin and paddings
        margin: '0',//to override heading tags pre-configured margin and paddings
        fontSize: '1.2rem',
        // fontWeight: 'normal',
        //lineHeight: '50px',
        color: theme.palette.secondary.light,
        fontWeight: '700',
    },
    headerWithSlideShowControls: {
        padding: '0 ',//to override heading tags pre-configured margin and paddings
        margin: '0',//to override heading tags pre-configured margin and paddings
        //marginRight:'55px',
        fontSize: '1.2rem',
        fontWeight: 'normal',
        lineHeight: '40px',
        color: theme.palette.secondary.light
    },
    fluidContainer: {//scrolleable
        height: '330px',
        overflowY: 'auto',
        overflowX: 'hidden'//so horizontal scrollbar is hidden
    },
    fluidContainer2: {//scrolleable
        height: '260px',
        overflowY: 'auto',
        overflowX: 'hidden'//so horizontal scrollbar is hidden
    },
    newsPost: {
        //border: '1px solid '+theme.customConstants.margins, test to see if customConstants was working on theme definition :DD yay it worked
        border: '1px solid ' + theme.palette.secondary.lighter,
        padding: '0px 0px',
        borderBottom: 'none !important',
        borderLeft: 'none !important',
        borderRight: 'none !important',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: theme.palette.hover.backgroundColor
        }
    },
    newsDescription: {
        color: theme.palette.secondary.main,
        marginBottom: '5px',
        '&:hover': {
            color: theme.palette.secondary.dark
        }
    },
    newsFooter: {
        color: theme.palette.secondary.light
    },
    slideshowContainer: {
        height: '370px',
        padding: '0px 20px',
        paddingTop: '30px',
        [theme.breakpoints.up('md')]: {
            padding: '0px 20px !important',
        },
        [theme.breakpoints.down('md')]: {
            //height: 'auto',
            //background: 'blue'
        },
        [theme.breakpoints.down('sm')]: {
            //height: 'auto',
            //background: 'red'
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            //background: 'yellow'
            paddingBottom: '10px'
        },
    },
    slideshowContainerNewCharts: {
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
            background: 'white',
            marginBottom: '1rem !important'
        },
    },
    graphCardContiner: {
        height: 'auto',
        // padding: '0px 20px',
        // [theme.breakpoints.up('sm')]: {
        //     padding: '0px 50px ',
        // },
        // [theme.breakpoints.up('840')]: {
        //     //padding: '0px 60px ',
        //     padding:'0px 0px 30px 10px'
        // },
        // [theme.breakpoints.up('md')]: {
        //     padding: '0px 20px !important',
        // }, 17/09/2021 no more quickfixes on the container, the root problem got fixed
    },
    graphCardContinerNew: {
        height: 'auto',
        // padding: '0px 20px',
        // [theme.breakpoints.up('sm')]: {
        //     padding: '0px 50px ',
        // },
        // [theme.breakpoints.up('840')]: {
        //     //padding: '0px 60px ',
        //     padding:'0px 0px 30px 10px'
        // },
        /* [theme.breakpoints.up('960')]: {
             maxHeight: '362.750px',
        }, // 17/09/2021 no more quickfixes on the container, the root problem got fixed */
    },
    mediaCard: {
        height: '490px'
    },
    media: {//to be used by images or videos insinde mediaContainer
        //objectFit:'contain',
        width: '100%',
        minHeight: '290px',
        maxHeight: '400px'
    },
    slideshowElementDescriptionContainer: {
        paddingTop: "20px",
        //padding:'0 20px' not needed anymore, since padding is being applied in upper class
    },
    mediaTitleContainer: {
        height: '50px',
        padding: '0 20px'
    },
    mediaTitle: {
        cursor: 'pointer',
        width: '100%',
        color: theme.palette.secondary.main,
        fontSize: '2rem'
    },
    mediaSubtitle: {
        width: '100%',
        color: theme.palette.secondary.light,
    },
    footerButtons: {
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('sm')]: {
            margin: '0px 10px !important'//solo margen horizontal
        },
    },
    footerButton: {
        backgroundColor: '#FFF',
        color: theme.palette.primary.main,
        lineHeight: '49px',
        fontSize: '1.6rem',
        cursor: 'pointer',
        flex: 0.5,///porque asumimos que solo serán dos botones. :P ademas ni hay tanto espacio para que fueran más.
        border: '1px solid ' + theme.palette.secondary.lighter,
        borderTop: '0 !important',
        display: 'flex',//este a su vez tambien es un flex container, para poder alinearlo perfectamente al centro
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    slideshowLeftIcon: {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        border: '1px solid ' + theme.palette.secondary.light,
        color: theme.palette.primary.main,
        borderRadius: '2px',
        position: 'absolute',
        top: '14px',
        right: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.6rem'
    },
    slideshowRightIcon: {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        border: '1px solid ' + theme.palette.secondary.light,
        color: theme.palette.primary.main,
        borderRadius: '2px',
        position: 'absolute',
        top: '14px',
        right: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.6rem'
    },
    FeedItem: {

        textAlign: "left !important",
        alignItems: 'flex-start'
    },
    graphOptionsMenuContainer: {
        flex: '1',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    graphOptionsMenuButton: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "#f2f3f6",
        height: '20px',
        padding: '4px 10px',
        cursor: 'pointer',
        border: '1px solid ' + theme.palette.secondary.lighter,
        borderStyle: 'none',
        borderBottomStyle: 'solid',
        boxShadow: '0 2px 0 ' + theme.palette.secondary.lighter,
        '&:hover': {
            backgroundColor: theme.palette.hover.backgroundColor
        }
    },
    graphOptionsMenuIcon: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '7px'
    },
    graphCardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 20px',
        marginBottom: '5px',
        borderBottom: '1px solid' + theme.palette.secondary.lighter,
        position: 'relative',
        height: '50px'
    }
})
export default baseCardstyle;