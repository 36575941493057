import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/institutionScreenstyle.js";

import { Grid, Typography, Button, Link } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";
import GraphCard from "components/flujograma/GraphCard.js";
import YoutubeEmbed from "components/general/YoutubeEmbed.js";
import 'assets/css/dashboard.scss';
import BaseScreenIndicadores from "./BaseScreen.jsx";
import MultipleHeaderMenu from "components/indicadores/MultipleHeaderMenu.jsx";
import clsx from "clsx";

const useStyles = makeStyles(styles);

export default function DashboardScreen(props) {

    var threshold = 12;
    const classes = useStyles();
    const location = useLocation();
    let { id, type } = useParams();
    const [thisDashboard, setThisDashboard] = React.useState({ isLoading: true, html_template: '<div>loading...</div>' });
    const [thisInstitution, setThisInstitution] = React.useState({ id: 1, type: 1, isLoading: true, name: 'No hay dashboard disponible para esta institución, redirigiendo a la página de bienvenida...' /*remove fakeData once backend is ready*/ });
    const [extraDisplayed, setExtraDisplayed] = React.useState({ displayed: false });
    const [selectedOption, setSelectedOption] = React.useState(0);
    const [options, setOptions] = React.useState([]);
    const [showDashaboard, setShowDashboard] = React.useState(false);

    function catchTimer() {
        let dropdown = document.querySelectorAll('select.dashboardselector');
        let i;
        if (dropdown.length > 0) {
            for (i = 0; i < dropdown.length; i++) {
                if (!dropdown[i].dataset.changeLoaded) {
                    const params = new URLSearchParams(window.location.search);
                    const val = params.get(dropdown[i].name);
                    for (let option of dropdown[i].options) {
                        if (option.getAttribute('value') === val) {
                            option.setAttribute('selected', true);
                            let vals = document.getElementsByName(dropdown[i].name + '-value');
                            vals.forEach(v => { v.innerHTML = val })
                        }
                    }

                    dropdown[i].addEventListener("change", function (evc) {
                        var selectedElement = evc.target.item(evc.target.selectedIndex);
                        if (selectedElement.hasAttribute('goTo')) {
                            /* props.history.push(selectedElement.getAttribute('goTo')); */
                            //window.location = selectedElement.getAttribute('goTo');
                            const params = new URLSearchParams(window.location.search);
                            let newQueryString = '?';
                            for (const value of params) {
                                if (value[0] === evc.target.name) {
                                    newQueryString += evc.target.name + '=' + selectedElement.getAttribute('value') + '&'
                                } else
                                    newQueryString += value[0] + '=' + value[1] + '&'
                            };
                            newQueryString = newQueryString.substring(0, newQueryString.length - 1);
                            /* props.history.push(newQueryString); */
                            // window.location.search = newQueryString;
                            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + newQueryString;
                            window.history.pushState({ path: newurl }, '', newurl);
                            // window.history.replaceState({ path: newurl }, "", newurl);

                            let headers = {};
                            let userLocal = localStorage.getItem('userInfo')
                                ? JSON.parse(localStorage.getItem('userInfo'))
                                : null;
                            if (userLocal && userLocal.access_token) {
                                headers.Authorization = `Bearer ${userLocal.access_token}`;
                            }
                            const queryString = window.location.search.length === 0 ? '' : '&' + window.location.search.substring(1, window.location.search.length);
                            /* setShowDashboard(true); */
                            axios.get(`${ApiURLs.baseURL}dashboard/get?key=${thisDashboard.data?.key}${queryString}`, {
                                headers: headers
                            }).then((data) => {
                                if (!data.data.error) {
                                    setThisDashboard((prev) => ({ isLoading: false, data: { ...prev.data, html_template: data.data.data } }));
                                }
                            })/* .finally(() =>
                                setTimeout(() => {
                                    setShowDashboard(false);
                                }, 1)); */
                        }
                    });
                    dropdown[i].setAttribute('data-change-loaded', true);
                } else {
                    const params = new URLSearchParams(window.location.search);
                    let newQueryString = '?';
                    if (window.location.seach === undefined) {
                        newQueryString += dropdown[i].name + '=' + dropdown[i].value
                    } else {
                        for (const value of params) {
                            if (value[0] === dropdown[i].name) {
                                newQueryString += dropdown[i].name + '=' + dropdown[i].value + '&'
                            } else
                                newQueryString += value[0] + '=' + value[1] + '&'
                        };
                        newQueryString = newQueryString.substring(0, newQueryString.length - 1);
                    }
                    if (newQueryString !== decodeURIComponent(window.location.search))
                        props.history.push(newQueryString);
                }
            }
        }
    }

    React.useEffect(() => {
        // setShowDashboard(true);
        catchTimer();
    });

    React.useEffect(() => {
        /* axios.get(ApiURLs.baseURL + 'dashboard/get?key=' + id).then((data) => {
            setThisDashboard({ isLoading: false, data: data.data.data });
        }); */
        let headers = {};
        let userLocal = localStorage.getItem('userInfo')
            ? JSON.parse(localStorage.getItem('userInfo'))
            : null;
        if (userLocal && userLocal.access_token) {
            headers.Authorization = `Bearer ${userLocal.access_token}`;
        }
        const queryString = window.location.search.length === 0 ? '' : '&' + window.location.search.substring(1, window.location.search.length);
        axios.get(`${ApiURLs.baseURL}dashboard/institution?id=${id}${queryString}`, {
            headers: headers
        }).then((data) => {
            if (!data.data.error) {
                setThisInstitution({ isLoading: false, data: data.data.institution });

                if (data.data.options.length === 0) {
                    setTimeout(() => {
                        window.location.href = '/indicadores';
                    }, 500);
                }
                searchOptions(data.data.options)
                setOptions(data.data.options);
            }
        }).catch(() => {
            setOptions([]);
        });
    }, [location, id]);

    React.useEffect(() => {
        searchOptions(options);
        setShowDashboard(true);
        let dropdown = document.querySelectorAll('select.dashboardselector');
        let i;
        let queryString = ''
        if (dropdown.length > 0) {
            for (i = 0; i < dropdown.length; i++) {
                if (dropdown[i].dataset.changeLoaded) {
                    queryString += dropdown[i].name + '=' + dropdown[i].options[0].getAttribute('value') + '&'
                    queryString = queryString.substring(0, queryString.length - 1);
                    dropdown[i].options[0].setAttribute('selected', true);
                }
            }
            if (queryString !== '') {
                var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryString;
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }

        setTimeout(() => {
            setShowDashboard(false);
        }, 100);
    }, [type]);

    const searchOptions = (options) => {
        if (type === undefined) {
            setSelectedOption(0);
            setThisDashboard({ isLoading: false, data: options[0] });
        } else {
            const selected = options.findIndex(opt => opt.key === type);
            setSelectedOption(selected === -1 ? 0 : selected);
            setThisDashboard({ isLoading: false, data: options[selected === -1 ? 0 : selected] });
        }
    }

    /* React.useEffect(() => {
        console.log('window.location.search', window.location.search);
        console.log('undefined', window.location.seach === undefined);
        if (window.location.seach === undefined) {
    
        } else {
            const params = new URLSearchParams(window.location.search);
            for (const value of params) {
                console.log('value', value);
                const dropdown = document.getElementsByName(value[0]);
                if (dropdown.length > 0) {
                    dropdown[0].value = value[1];
                }
            };
        }
    }, [thisDashboard]); */

    return (
        <BaseScreenIndicadores indicadoresHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{ display: "Inicio", href: "/index" }]}>
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>

                    {
                        thisInstitution.isLoading ? <i class="fas fa-spinner" />
                            :
                            options.length === 0 ?
                                <Typography variant="h1" className={classes.title}>
                                    No hay dashboard disponible para esta institución, redirigiendo a la página de bienvenida...
                                </Typography>
                                :
                                (
                                    !thisInstitution.isLoading ?
                                        <div style={{ width: '100%' }}>
                                            <Typography variant="h1" className={classes.title} >
                                                {(!thisInstitution.isLoading) ? thisInstitution.data.name : <i class="fas fa-spinner"></i> /*id */}
                                            </Typography>
                                            <div className={classes.subtitleContainer}>
                                                <Typography variant="body1" className={classes.longDescription}>
                                                    {(!thisInstitution.isLoading) ? thisInstitution.data.description : <i class="fas fa-spinner"></i>}
                                                </Typography>
                                            </div>
                                            {
                                                <div className={classes.typeParent}>
                                                    <Typography variant="h1" className={classes.typeContainer}>
                                                        <MultipleHeaderMenu
                                                            options={options}
                                                            rootComponent={
                                                                <Link onClick={(event) => event.preventDefault()} to='#' style={{ cursor: 'pointer' }}>
                                                                    <div className={clsx(classes.navLink, classes.mobileHidden)}>
                                                                        {options[selectedOption]?.title}&nbsp;
                                                                        <i className='fa fa-caret-down ' />
                                                                    </div>
                                                                </Link>
                                                            }
                                                            prefix={"/dashboard/" + id + '/'}
                                                            close={() => { }}
                                                            margin={true}
                                                            children={true}
                                                            selected={options[selectedOption]?.id}
                                                            search={'key'}
                                                        />
                                                    </Typography>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <i class="fas fa-spinner" />
                                )
                    }
                    <div
                        style={{ display: showDashaboard ? 'none' : '', width: '100%' }}
                        dangerouslySetInnerHTML={{ __html: thisDashboard.data?.html_template }}
                    />
                </Grid>
            </Grid>
        </BaseScreenIndicadores>
    )
}
