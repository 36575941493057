import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
//import logo4 from "assets/img/lasalleLogo.svg"; logo to be displayed
import styles from "../../assets/jss/headerstyle.js";
const useStyles = makeStyles(styles);
export default function SecondaryHeader(props){
    const classes = useStyles();
    return (
        <AppBar position='static'  >
            <Toolbar className={classes.primary}>
                <div className={classes.leftContent}>
                    <h3>3</h3>
                    <h3>2</h3>
                    <h3>1</h3>
                </div>
                <div className={classes.rightContent}>
                    <h3>3</h3>
                    <h3>2</h3>
                    <h3>1</h3>
                </div>
            </Toolbar>
        </AppBar>
    );
}