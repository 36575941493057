import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import footerLogo from "assets/img/logosFooter.svg";
const useStyles = makeStyles((theme) => ({
    footer: {
        marginTop:'80px'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    footerImage:{
        width:'100%',
        maxWidth: "450px",
        height:'auto',
    }
}));
export default function Footer(props) {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <div className={classes.contentContainer}>
                <img src={footerLogo} className={classes.footerImage} />
            </div>
        </footer>
    )
}