import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import BaseScreen from "./BaseScreen.js";
import FeedsCard from "components/flujograma/FeedsCard.js";
import SlideshowCard from "components/flujograma/SlideshowCard.js";
import D3LinearChart from "components/flujograma/D3LinearChart.js";
import { Typography } from "@material-ui/core";
import styles from "assets/jss/primaryScreenstyle.js";
import BaseCard from "components/flujograma/BaseCard.js";
import YoutubeEmbed from "components/general/YoutubeEmbed.js";
import axios from 'axios';
import ApiURLs from "config/ApiURLs.js";
import { Link } from "react-router-dom";
import LoadingIndicator from "components/general/LoadingIndicator.js";

const useStyles = makeStyles(styles);



const d3Data = [
    {
      "name": "Eficiencia anual",
      "year": 2014,
      "type": "percentage",
      "data": 13.5
    },
    {
      "name": "Eficiencia anual",
      "year": 2015,
      "type": "percentage",
      "data": 22.8
    },
    {
      "name": "Eficiencia anual",
      "year": 2016,
      "type": "percentage",
      "data": 22.7
    },
    {
      "name": "Eficiencia anual",
      "year": 2017,
      "type": "percentage",
      "data": 25.6
    },
    {
      "name": "Eficiencia anual",
      "year": 2018,
      "type": "percentage",
      "data": 31.6
    },
    {
      "name": "Eficiencia anual",
      "year": 2019,
      "type": "percentage",
      "data": 31.8
    },
    {
      "name": "eficiencia anual",
      "year": 2020,
      "type": "percentage",
      "data": 24.9
    }
  ];

export default function PrimaryScreen(props) {
    //const appContext = React.useContext(AppContext);
    //const rootRef = React.useRef(null);
    let baseYear = (new Date().getFullYear())-1;
    const classes = useStyles();
    //const [searchValue, setSearchValue] = React.useState('');

    const [videoList, setVideoList] = React.useState({ isLoading: true, data: {'name':'...'} /*remove fakeData once backend is ready*/ });
    const [mainChart, setMainChart] = React.useState({isLoading:true, data: {}});

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/video-list?id=vl002').then((data) => {  
             setVideoList({isLoading:false,data:data.data});
        });

        axios.get(ApiURLs.baseURL+'website/chart?id=C-GLOBAL-004').then((data) =>{
            setMainChart({isLoading:false, data: data.data});
        });
    }, []);

    /*const handleChange = (newValue) => {
        setSearchValue(newValue);
    };*/
    return (
        <BaseScreen primaryHeader bodyColor="#f2f3f6">
            <div>
                <Typography variant="h1" className={classes.title}>Flujograma de la Justicia Criminal</Typography>
                <div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Hacia un sistema de justicia transparente, eficiente y digital.</Typography>
                </div>
            </div>


            <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                {
                    videoList.isLoading? <LoadingIndicator/> : <FeedsCard newsFeeds={videoList.data.videos} title={'VIDEOS'} buttons={[]} variant='videolist' />
                }
                   
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                {   
                    mainChart.isLoading ? <LoadingIndicator/> : <SlideshowCard slideElements={[{ main: <D3LinearChart width={700} height={270} source={mainChart.data.data} isPreview />, title: 'Eficiencia Global del Sistema', subtitle: 'La eficiencia global del sistema a través de los años', chartId:'C-GLOBAL-004'}]} />
                }
                </Grid>
                {/*
                <Grid item xs={12} sm={6} md={4}>
                    <MediaCard title={'Lanzamiento de la plataforma'} subtitle={'05 octubre 2021'} image={invitationImage} />
                </Grid>
                */}
                <Grid item xs={12} sm={6} md={6}>
                    <BaseCard height="100%">
                        <YoutubeEmbed embedId="nBXq7vNn-VM" />
                    </BaseCard>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <BaseCard height="100%">
                    <Link to={"/flujograma/flujograma/"+baseYear}>
                    <img src='/history/animated.gif' style={{width:"100%",height:"100%"}} alt="Flujograma de la Justicia Criminal" />
                    </Link>
                   
                </BaseCard>
                </Grid>
            </Grid>

        </BaseScreen>
    )
}
