import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { AppProvider } from 'state/AppContext';
import ReactGA from 'react-ga4';
import ChartDetailScreen from 'views/flujograma/ChartDetailScreen';
import InstitutionScreen from 'views/flujograma/InstitutionScreen';
import InstitutionsScreen from 'views/flujograma/InstitutionsScreen';
import TitleAndBodyScreen from 'views/flujograma/TitleAndBodyScreen';
import FlujogramaScreen from 'views/flujograma/FlujogramaScreen';
import FlujogramaScreenTrimester from 'views/flujograma/FlujogramaScreenTrimester';
import PrimaryScreen from 'views/flujograma/PrimaryScreen';

import GlobalScreen from 'views/flujograma/GlobalScreen';
import PrimaryScreenGeneral from 'views/PrimaryScreen';

import PrimaryScreenIndicadores from 'views/indicadores/PrimaryScreenIndicadores';
import DashboardScreen from 'views/indicadores/DashboardScreen';
import EmbedScreen from 'views/indicadores/EmbedScreen';
import InstitutionScreenIndicadores from 'views/indicadores/InstitutionScreen';
import ChartDetailScreenIndicadores from 'views/indicadores/ChartDetailScreen';
import TitleAndBodyScreenIndicadores from 'views/indicadores/TitleAndBodyScreen';

import './index.css';
import './assets/css/dashboard.scss';
import LoginScreen from 'views/user/LoginScreen';
import TermsAndConditionsScreenGeneral from 'views/indicadores/TitleAndBodyScreenGeneral';

ReactGA.initialize('G-JKFR3MYDLD');
const hist = createBrowserHistory();
hist.listen((location, action) => {
	window.scrollTo(0, 0);
	//console.log("Desde history.....");
});
const GlobalFontFamily = "'Avenir Next LT Pro Regular','Open Sans', 'sans-serif'";
const commonValues = {
	fontSize: '1.6rem',
	fontWeight: 600,
	lineHeight: '140%',
	fontFamily: GlobalFontFamily,
	'@media (max-width: 600px)': {
		fontSize: '1.5rem'
	},
	color: "#003a6f",
};

const customTheme = createMuiTheme({
	palette: {
		primary: {
			//main: '#1f95dd' //lightblue - celeste
			main: '#003b71' //lightblue - celeste #6389a1
		},
		secondary: {
			lighter: '#e4e9ec',////Even lighter, generally used for margins in cards (refer to BaseCard)
			light: '#81878C',//lighter gray
			main: '#50595E',//gray - gris
			dark: '#003b71', //'#101f28', //almost black
		},
		hover: {
			backgroundColor: '#f8f9fa'
		}
	},
	typography: {
		h1: {
			fontSize: '2.4rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: GlobalFontFamily,///because we are setting the default fontFamily on index.css
			'@media (max-width: 600px)': {
				fontSize: '2.4rem'
			},
			color: "#003a6f",
		},
		h2: {
			fontSize: '1.8rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: GlobalFontFamily,///because we are setting the default fontFamily on index.css
			'@media (max-width: 600px)': {
				fontSize: '1.6rem'
			},
			color: "#003a6f",
		},
		h3: commonValues,
		h4: commonValues,
		h5: commonValues,
		caption: {
			fontSize: '1.4rem',
			fontFamily: GlobalFontFamily,
			letterSpacing: "0.01111em"
		},
		subtitle2: { ///for elements such as the newsFooter on FeedsCard. Generally they are the smallest subtitles and are represented by an span tag
			fontSize: '1.3rem',
			fontFamily: GlobalFontFamily,///because we are setting the default fontFamily on index.css
		},
		body1: {
			fontSize: "1.5rem",
			fontFamily: GlobalFontFamily,
		}
	},
	customConstants: {
		pageBackgroundColor: "#f2f3f6"
	}
});


ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={customTheme}>
			<AppProvider>
				<Router history={hist}>
					<Switch>
						
						<Route path="/flujograma/bienvenido" component={PrimaryScreen} />
						<Route path="/flujograma/viewChart/:id" component={ChartDetailScreen} />
						<Route path="/flujograma/global" component={GlobalScreen}  exact/>
						<Route path="/flujograma/institutions" component={InstitutionsScreen}  exact/>
						<Route path="/flujograma/institution/:id" component={InstitutionScreen} />
						<Route path="/flujograma/flujograma-trimestre/:year/:trimester" component={FlujogramaScreenTrimester} />
						<Route path="/flujograma/flujograma/:year" component={FlujogramaScreen} />
						<Route path="/methodology" component={TermsAndConditionsScreenGeneral} exact key={()=>{Math.random()}} />
						<Route path="/terms-and-conditions" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} />
						<Route path="/faq" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} />
						<Route path="/troubleshooting" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} />
						<Route path="/flujograma/methodology" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} /> 

						<Route path="/indicadores/methodology" component={TitleAndBodyScreenIndicadores} exact key={() => { Math.random() }} />
						<Route path="/viewChart/:id" component={ChartDetailScreenIndicadores} />
						<Route path="/institution/:id/:type" component={InstitutionScreenIndicadores} />
						<Route path="/institution/:id" component={InstitutionScreenIndicadores} />
						<Route path="/bienvenido" component={PrimaryScreenGeneral} />
						<Route path="/indicadores" component={PrimaryScreenIndicadores} />
						<Route path="/embed/:type/:id" component={EmbedScreen} />
						<Route path="/dashboard/:id/:type" component={DashboardScreen} />
						<Route path="/dashboard/:id" component={DashboardScreen} />

						<Route path="/user/login" component={LoginScreen} />
						<Redirect to="/bienvenido" />
					</Switch>
				</Router>
			</AppProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
const SendAnalytics = ()=> {
	ReactGA.send({
	  hitType: "pageview",
	  page: window.location.pathname,
	});
  }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
