import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width:'100%',
        justifyContent:'center',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        
      },
    indicator:{
        color:theme.palette.primary.light
    }
}));

export default function LoadingIndicator(props){
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.indicator} ></CircularProgress>
        </div>
    )
}